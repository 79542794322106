
/*
@font-face {
  font-family: 'Noto Sans KR';
  font-display: auto;
  src:url('./NotoSansKR-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
*/

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('./NotoSansKR-Thin.otf') format('opentype');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('./NotoSansKR-Light.otf') format('opentype');
  }
  @font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 400;
     src: url('./NotoSansKR-Regular.otf') format('opentype');
   }
  @font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 500;
     src: url('./NotoSansKR-Medium.otf') format('opentype');
   }
  @font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 700;
     src: url('./NotoSansKR-Bold.otf') format('opentype');
   }
  @font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 900;
     src: url('./NotoSansKR-Black.otf') format('opentype');
   } 
  